var formHelpers4i = function(){
	const loadingIcon = '<div class="spinner" role="alert" aria-busy="true" aria-atomic="true" aria-label="Loading, please wait"><div class="bounce1"></div><div class="bounce2"></div><div class="bounce3"></div></div>';
	const loadingBtn = '<div class="busyBtn" role="alert" aria-busy="true" aria-atomic="true" aria-label="Loading, please wait"><span></span><span></span></div>';
	const loadingPageSection = '<div id="loadingPage" class="spinnerOverlay"><div class="spinnerCtrPg">' + loadingIcon + '</div></div>';
	const headerToken = document.querySelectorAll('input[name=__RequestVerificationToken]')[0] ? document.querySelectorAll('input[name=__RequestVerificationToken]')[0].value : "";
  	const recaptchaKey = document.getElementById("RecaptchaSiteKey") ? document.getElementById("RecaptchaSiteKey").value : "";
  	const recaptchaName = document.getElementById("RecaptchaActionName") ? document.getElementById("RecaptchaActionName").value : "";
  	const errorMsgPanel = `
  		<div id="ErrorBox" class="ErrorMsgPanel" role="alert" aria-atomic="true">
  			<h3 id="ErrorTitle" class="validationHeading">Please correct the following errors and resubmit:</h3>
  			<ul id="ErrorMsgBox"><li tabindex="0" class="errorItem"></li></ul>
  		</div>
  	`;

	const form = {
		element: null,
		heading: null,
		requiredFields: [],
		invalidFields: [],
		fieldstoValidate: [],
		inputFields: [],
		requiredFieldsets: [],
		errorMessages: [],
		isValid: true, //todo what is the diff between isValid and resultIsValid
		resultIsValid: false,
		errorList: [], //todo what is the diff between errorList and errorLine
		errorLine: []
	};
	
	/*const showLoadingAfter = (elementToAppend) => {
		if(elementToAppend) elementToAppend.insertAdjacentHTML('afterend', loadingIcon);
	};*/
	const showBusyBtn = (button) => {
		if(button) {
			if(button.querySelector(".busyBtn")) button.querySelector(".busyBtn").remove();
			let currentText = button.innerHTML;
			button.innerHTML = loadingBtn + currentText;
		}
	};
	const clearBusyBtn = () => {
		if(document.querySelector(".busyBtn")) document.querySelector(".busyBtn").remove();
	};
	const showLoadingOverlay = (container) => {
		container.insertAdjacentHTML("beforeend", loadingPageSection);
	};
	const removeLoadingOverlay = () => {
		const loadingOverlay = document.getElementById("loadingPage");
		if(loadingOverlay) loadingOverlay.remove();
	};
	const showSuccessStatus = (elementToPrepend, msg) => {
  		clearStatus();
		elementToPrepend.insertAdjacentHTML('beforebegin', '<p class="statusMsg text16 padding5 paddingLeft10 textSemiBold roundCorners5 bkgdLtGreen marginTop5 marginBtm10" role="status">' + msg + '</p>');
		let status = document.querySelector(".statusMsg");
		scrollAndFocus(status);
	};
	const showErrorStatus = (elementToPrepend, msg) => {
  		clearStatus();
		elementToPrepend.insertAdjacentHTML('beforebegin', '<p class="statusMsg text16 padding5 paddingLeft10 textSemiBold roundCorners5 bkgdLtRed textDkRed marginTop5 marginBtm10" role="status">' + msg + '</p>');
		let status = document.querySelector(".statusMsg");
		scrollAndFocus(status);
	};
	const scrollAndFocus = (element) => {
		element.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" });
		// set focus if there's a link or a button
		if(element.querySelector("a")) element.querySelector("a").focus();
		else if(element.querySelector("button")) element.querySelector("button").focus();
	};
	const clearStatus = () => {
		const msgs = document.querySelectorAll(".statusMsg");
		if(msgs.length > 0){
			for(let i = 0; i < msgs.length; i++){
				msgs[i].remove();
			}
		}
	};
	const removeErrorBox = () => {
		const errorBox = document.getElementById("ErrorBox");
		if(errorBox) errorBox.remove();
	};
	
	return{
		Form: form,
		LoadingIcon: loadingIcon,
		HeaderToken: headerToken,
		RecaptchaKey: recaptchaKey,
		RecaptchaName: recaptchaName,
		ErrorMsgPanel: errorMsgPanel,
		ShowSuccessStatus: showSuccessStatus,
		ShowErrorStatus: showErrorStatus,
		ClearStatus: clearStatus,
		ShowBusyBtn: showBusyBtn,
		ClearBusyBtn: clearBusyBtn,
		ShowLoadingOverlay: showLoadingOverlay,
		RemoveLoadingOverlay: removeLoadingOverlay,
		RemoveErrorBox: removeErrorBox
	};
};